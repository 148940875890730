<template>
    <div class="fixed inset-0 bg-white z-50" v-if="showLightBox">
        <div
            class="relative flex items-center justify-center w-12 h-12 cursor-pointer ml-auto"
            @click="toggleLightBox()"
        >
            <i class="fa-solid fa-xmark text-large"></i>
        </div>
        <div v-if="additionalData && lightBoxIndex !== null" class="h-full">
            <div
                v-html="additionalData[lightBoxIndex]"
                class="iframe-container h-full"
            ></div>
        </div>
    </div>
    
    <div class="flex pb-20 m:pb-12">
        <NCarousel
            :slides-per-view="slides.length > 1 ? 'auto' : 1"
            :space-between="20"
            :loop="false"
            :draggable="slides.length > 1"
            show-arrow
        >
            <n-carousel-item
                class="!w-5/24 min-w-[275px] m:!w-10/24 m:min-w-[250px] m:max-w-[325px] s:!w-16/24 s:min-w-[300px]"
                v-for="(child, index) in slides"
                :key="index"
            >
                <a
                    :key="index"
                    :href="openInLightBox ? null : child.linkfeldNoText?.url"
                    @click="
                        () => (openInLightBox ? toggleLightBox(index) : null)
                    "
                    class="w-full grid grid-cols-1 gap-x-12 gap-y-2 items-center teaser-card cursor-pointer"
                >
                    <div
                        class="img-container teaser-img-container overflow-y-hidden"
                    >
                        <img
                            :src="
                                child.bild[0]?.url ||
                                'https://via.placeholder.com/1'
                                /* TODO: Find some other default image that fits the style / design requirements */
                            "
                            :alt="child.bild[0]?.alt || 'Teaser Bild'"
                            class="object-cover w-full"
                            :class="[
                                { 'rounded-2xl': roundedCorners },
                                props.aspectAuto
                                    ? 'aspect-auto'
                                    : 'aspect-square',
                            ]"
                        />
                    </div>

                    <div class="text-f5 pt-2 item-title teaser-name">
                        {{ child.ueberschrift }}
                    </div>
                    <div
                        class="text-regular cursor-pointer ckeditor-content"
                        v-html="child.teaserText"
                    ></div>
                </a>
            </n-carousel-item>

            <template #dots="{ total, currentIndex, to }">
                <div class="n-carousel__dots n-carousel__dots--dot">
                    <div
                        v-for="index of total"
                        v-if="slides.length < 7"
                        aria-selected="false"
                        role="button"
                        tabindex="0"
                        class="n-carousel__dot"
                        :class="{
                            ['n-carousel__dot--active']:
                                currentIndex === index - 1,
                        }"
                        @click="to(index - 1)"
                    ></div>
                </div>
            </template>
            <template #arrow="{ prev, next }">
                <div v-if="slides.length > 1" class="custom-arrow">
                    <button
                        type="button"
                        class="btn-round-primary-dark custom-arrow--left"
                        @click="prev"
                    >
                        <i
                            :class="CONFIG_OPTIONS.global.iconStyle"
                            class="fa-caret-left"
                        ></i>
                    </button>
                    <button
                        type="button"
                        class="btn-round-primary-dark custom-arrow--right"
                        @click="next"
                    >
                        <i
                            :class="CONFIG_OPTIONS.global.iconStyle"
                            class="fa-caret-right"
                        ></i>
                    </button>
                </div>
            </template>
        </NCarousel>
    </div>
</template>

<script lang="ts" setup>
import { NCarousel, NCarouselItem } from "naive-ui";
import { Module_TeaserEintrag_BlockType } from "@gql-types/types.generated";
import { CONFIG_OPTIONS } from "@src/globals";
import { ref } from "vue";

const props = defineProps<{
    data: Array<Module_TeaserEintrag_BlockType>;
    aspectAuto?: boolean;
    openInLightBox?: boolean;
    additionalData?: any;
}>();
const slides = props.data;

const roundedCorners = CONFIG_OPTIONS.global.roundedCorners;

let showLightBox = ref(false);
let lightBoxIndex = ref<number>(null);

function toggleLightBox(index: number = null) {
    lightBoxIndex.value = index;
    const newVisible = !showLightBox.value;
    showLightBox.value = newVisible;
    // Disable scrolling while lightbox is visible
    document.body.classList.toggle("overflow-hidden", newVisible);
}
</script>

<style lang="scss" scoped>
.n-carousel
    .n-carousel__dots.n-carousel__dots--dot
    .n-carousel__dot.n-carousel__dot--active {
    background-color: black;
}

.n-carousel.n-carousel--bottom .n-carousel__dots {
    left: 0;
}

.n-carousel .n-carousel__dots.n-carousel__dots--dot .n-carousel__dot {
    background-color: #8d8d8d;
}

.n-carousel__dot:first-child {
    margin-left: 0;
}

.custom-arrow {
    @apply flex gap-x-4 h-max flex-wrap w-max ml-auto mt-12;

    font-size: 1.25rem;
}

.custom-arrow button:hover {
    @apply shadow;
}

.custom-arrow button:active {
    transform: scale(0.95);
    transform-origin: center;
}

.teaser-card {
    @screen minL {
        .teaser-img-container,
        .teaser-name {
            @apply transition-all duration-300;
            @apply underline decoration-transparent;
        }

        &:hover {
            .teaser-img-container {
                @apply scale-105;
            }
            .teaser-name {
                @apply decoration-black;
            }
        }
    }
}
</style>
